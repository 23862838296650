import React from "react";
import Layout from "../components/Layout";
import { useStaticQuery, graphql } from "gatsby";
import PrivacyPolicyContent from "../components/PrivacyPolicyContent";

export default () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  );

  const { siteUrl } = site.siteMetadata;

  return (
    <Layout
      pageTitle="Our Privacy Policy At The Comparison Company"
      pageDescription="Protecting your private information is our priority at The Comparison Company. Read our privacy policy to understand how we do this."
    >
      <PrivacyPolicyContent siteUrl={siteUrl} />
    </Layout>
  );
};
